.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
  color: var(--Black-Shade-Para_Text, #949494);
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  & span {
    color: var(--Black-Shade-Text_Black, #272727);
  }
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
  & button {
    color: var(--Black-Shade-Para_Text, #949494);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: transparent;
    border: none;
    padding: 8px;
    &:focus, &:hover{
      box-shadow: none;
    }
  }
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
  & span {
    color: var(--Black-Shade-Text_Black, #272727);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
